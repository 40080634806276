import { FC, memo, useMemo } from 'react'
import RegDialog from '../../RegDialog/desktop'
import { EQueueElements } from '../../RegDialog/types'
import { IParentFormDialogSuccessProps } from '../types'
import { socialText } from '../constants'

const ParentFormDialogSuccess: FC<IParentFormDialogSuccessProps> = (props) => {
  const { open, onClose, isInstitution, description, isNeedVk = true, templateId } = props

  const descriptionText = useMemo(() => {
    if (description) {
      return description
    }

    return isInstitution ? socialText.description2 : socialText.description
  }, [description, isInstitution])

  const messengersQueue = useMemo(() => {
    const queue = [] as EQueueElements[]
    queue.push(EQueueElements.tg)

    if (isNeedVk) {
      queue.push(EQueueElements.vk)
    }
    return queue
  }, [isNeedVk])

  const queryParams = useMemo(() => {
    let params: Record<string, any> = {
      templateId,
    }

    if (!isNeedVk) {
      params = {
        ...params,
        channelId: 4,
      }
    }
    return params
  }, [isNeedVk, templateId])

  return (
    <RegDialog
      title={socialText.title}
      description={descriptionText}
      open={open}
      onClose={onClose}
      params={queryParams}
      queue={messengersQueue}
    />
  )
}

export default memo(ParentFormDialogSuccess)
